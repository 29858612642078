<template>
  <div>
    <div class="page_container">
      <div class="img_box">
        <img :src="imageUrl"/>
      </div>
      <div class="article_list">
        <ul class="tabs">
          <li :class="{active: curActive == index}" v-for="(item, index) in typeList" :key="index"
              @click="toggleTab(item,index)">{{ item.ntName }}
          </li>
        </ul>
        <ul class="nav_item">
          <li v-for="(item, index) in navList" :key="index" @click="skipLink(item)">
            <div class="header_box">
              <img :src="item.logUrl"/>
              <div class="tool_tip">
                <span>{{ item.naName }}</span>
                <span class="scan"><i class="fa fa-eye"></i>{{ item.scanTimes }}</span>
              </div>
            </div>
            <div class="content">{{ item.introduction }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "index",
  data: () => ({
    typeList: [
      {ntId: '', isRecommend: 1, ntName: '推荐'}, {ntId: '', isRecommend: '', ntName: '全部'}
    ],
    curActive: 0,
    imageUrl: '',
    navList: []
  }),
  created() {
    let params = {
      typeId: '',
      isRecommend: 1
    }
    this.getTypeList()
    this.getNavList(params)
  },
  methods: {
    getTypeList() {
      this.axios.get('/navigation/type-list').then(res => {
        let result = this.typeList.slice()
        for (let item of res.list) {
          item.isRecommend = ''
        }
        this.typeList = result.concat(res.list)
      })
    },
    getNavList(params) {
      this.axios.get('/navigation/list-nav', {params}).then(res => {
        this.imageUrl = res.imageUrl
        this.navList = res.page
        console.log(res)
      })
    },
    toggleTab(item, index) {
      this.curActive = index
      this.navList = []
      let params = {
        typeId: item.ntId,
        isRecommend: item.isRecommend
      }
      this.getNavList(params)
    },
    skipLink(item) {
      window.open(item.naLink)
      let params = {
        naId: item.naId
      }
      this.axios.get('/navigation/click', {params})
    }
  },
};
</script>

<style lang="scss" scoped>
.page_container {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px 40px;
  margin-bottom: 40px;

  .img_box {
    width: 100%;
    height: 340px;
    padding-bottom: 20px;
    //border-bottom: 1px solid #E4E4E4;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .article_list {
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 40px;

    .tabs {;
      display: flex;
      position: relative;

      &:after {
        content: '';
        width: calc(100% + 80px);
        height: 1px;
        position: absolute;
        background: #f1f1f1;
        bottom: -3px;
        left: -40px;
        z-index: 1;
      }

      li {
        height: 50px;
        line-height: 50px;
        position: relative;
        color: #000000;
        cursor: pointer;
        user-select: none;

        &:after {
          content: '';
          width: 0px;
          position: absolute;
          bottom: -2px;
          height: 4px;
          left: 50%;
          transform: translateX(-50%);
          background: #3E35E7;
          border-radius: 8px;
          transition: all 0.3s ease-in;
          z-index: 3;
        }

        &:hover {
          color: #3E35E7;

          &:after {
            width: 120%;
          }
        }
      }


      // 选中
      .active {
        color: #3E35E7;

        &:after {
          width: 120%;
        }
      }

      // 分隔
      li:not(:last-child) {
        margin-right: 40px;
      }
    }

    .nav_item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 40px 0;

      li {
        width: 300px;
        border-radius: 8px;
        margin-bottom: 30px;
        padding: 20px;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        transition: all 0.2s ease-in;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);

        .header_box {
          height: 100px;
          display: flex;
          align-items: center;

          .tool_tip {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .scan {
              color: #666666;
              font-size: 14px;

              .fa-eye {
                margin-right: 10px;
                margin-top: 10px;
              }
            }

          }

          img {
            width: 86px;
            height: 86px;
            object-fit: cover;
            border-radius: 50%;
          }

          span {
            font-size: 1.2rem;
            font-weight: bold;
            margin-left: 10px;
          }
        }

        .content {
          font-size: 14px;
          line-height: 1.6rem;
          color: #666666;
          margin-top: 10px;
          word-wrap: break-word;
        }

      }

      li:hover {
        bottom: 3px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
      }

      li:last-child:nth-child(3n -1) {
        margin-right: 350px;
      }


      //@media (min-width: 1280px) {
      //  li {
      //    width: 340px;
      //    padding: 20px;
      //
      //    .img_inner_box {
      //      height: 320px;
      //    }
      //  }
      //  li:last-child:nth-child(3n -1) {
      //    margin-right: calc(435px);
      //  }
      //}
      //@media (min-width: 1690px) {
      //  ///* 如果最后一行是3个元素 */
      //  li:last-child:nth-child(4n - 1) {
      //    margin-right: calc(340px + 48px);
      //  }
      //  li:last-child:nth-child(4n - 2) {
      //    margin-right: calc(780px);
      //
      //  }
      //}
    }
  }
}
</style>
